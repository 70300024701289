// src/components/reviews.js
import React from "react";
import { Box, Heading, SimpleGrid, Image } from "@chakra-ui/react";

const ArtSectionObac = () => {
  return (
    <Box
      p={4}
      display="flex"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
    >
      <Heading as="h1" size="xl" mb={10}>
        OBAC Art
      </Heading>
      <ArtGridObac art={art} />
    </Box>
  );
};

const ArtGridObac = ({ art }) => {
  return (
    <SimpleGrid
      columns={{ base: 1, md: 3 }}
      spacing={10}
      mb={10}
      display="flex"
      flexWrap="wrap"
      justifyContent="center"
      alignItems="center"
    >
      {art.map((art, index) => (
        <ArtObac key={index} art={art} />
      ))}
    </SimpleGrid>
  );
};

const ArtObac = ({ art }) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Image
        src={art.image}
        alt={art.name}
        borderRadius="md"
        width="300px" // Set the desired width
        height="400px" // Set the desired height
        objectFit="cover" // Ensures the images cover the set dimensions
      />
    </Box>
  );
};
const art = [
  {
    name: "ObacArt 1",
    image: "/obac1.png",
  },
  {
    name: "ObacArt 2",
    image: "/obac2.png",
  },
  {
    name: "ObacArt 3",
    image: "/obac3.PNG",
  },
  {
    name: "ObacArt 4",
    image: "/obac4.jpeg",
  },
  {
    name: "ObacArt 5",
    image: "/obac5.png",
  },
  {
    name: "ObacArt 6",
    image: "/obac6.png",
  },

];

export default ArtSectionObac;
