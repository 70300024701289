import React from "react";
import {
  Box,
  Flex,
  Text,
  Image,
  Heading,
  useBreakpointValue,
  Link,
} from "@chakra-ui/react";

const booksData = [
  {
    id: 1,
    title: "the river of fire - book 1",
    cover: "/book1cover.png",
    blurb: (
      <>
        <i>
          He’ll never be the hero. But I clearly have the capacity to fall in
          love with a villain.
        </i>
        <br />
        <br />
        When Hell is plagued by overpopulation and exposure of their world to
        humankind seems imminent, angels and demons come to a rare accord. They
        decide that their half-mortal offspring will be the perfect soldiers to
        cull these rampant ranks of evil souls. Introvert Lana’s mundane life
        takes a terrifying turn when she is forcibly taken to the Underworld.
        There, she not only faces Hell’s lethal minions but also draws the
        dangerous interest of the archdemon Ashtaroth, an ancient being willing
        to kill anyone who stands in his way. While Lana fights the lust he
        stirs within her, unwilling to damn her principles for the gorgeous
        creature, Ashtaroth plots ways to bind her to him. To his delight, they
        soon find themselves united on a mission. Will Lana survive working with
        the demon who is intent on conquering her, or will their differences
        bring them closer in a way they never imagined?
        <br />
        <br />
        Trigger warnings:
        <br />
        <br />✣ Liberal interpretation of religious and occult texts
        <br />✣ Morally (dark) gray MMC <br />✣ Graphic violence <br />✣ Adult
        language <br />✣ Graphic sexual scenes which include: dubious consent
        (dubcon), degradation/praise kink, menstruation-related content
        ("sailing the red sea"), exhibitionism & an orgy, power play, breath
        play & MM sexual content
      </>
    ),
    link: "https://mybook.to/trof",
  },
  {
    id: 2,
    title: "the gates of hell - book 0.5",
    cover: "/book05cover.png",
    blurb: (
      <>
        <i>"I will be alone."</i>
        <br />
        <i>"You will never be alone as long as I exist."</i>
        <br />
        <br />
        While ancient Sumerians were etching their first words, Heaven and Hell
        waged bloody battles. Nephithar, Hell’s foremost general and a being
        crafted for bloodshed by the Devil himself, revels in the chaos of war.
        His name is whispered in fear across the Heavens, a testament to his
        ruthless reputation. The warrior’s only other pleasure lies in gazing
        upon the multitude of angel feathers he has collected as trophies over
        the centuries. That is, until one day, his gaze is irresistibly drawn to
        a pair of damaged wings shimmering with a silvery lilac hue in Hell’s
        inferno. Suddenly, Nephithar finds himself with a new obsession, willing
        to defy his very nature for unfettered access to those feathers – and
        the angel they belong to.
        <br/>
        <br/>
        Trigger warnings:
        <br />
        <br />✣ Liberal interpretation of religious and occult texts
        <br />✣ A villanous MMC <br />✣ Graphic violence <br />✣ Adult
        language <br />✣ Explicit sexual scenes <br />✣ Falling from grace
      </>
    ),
    link: "https://mybook.to/tgoh",
  },
  {
    id: 3,
    title: "the realm of shadows - book 1.5",
    cover: "/book15cover.png",
    blurb: (
      <>
        <i>“What’s that?”</i>
        <br />
        <i>“Your leash and collar. Pet.”</i>
        <br />
        <br />
        For thousands of years, the succubus Naamah did who she wanted, when she wanted. As a lust demon, she was no stranger to using her wiles to achieve her goals. So when her uncle, the archdemon Ashtaroth, asked her to seduce and distract a young Cambion, she took on the mission with horns gleaming and tail swinging. Yet, she finds the mortal far more intriguing than she anticipated. Especially since the boy refuses to admit he’s most comfortable under his Mistress’s boot.
        <br/>
        Kevin grew up on the streets, used to ignoring his needs and focusing solely on survival. Abducted into Hell and trained to face its dangers, he thought he was doing pretty well… until he met a curly-haired succubus who liked to stick her tail where it didn’t belong. Now, he’s got all these new desires he’s not used to denying – each centered around the bossy wench.
        <br/>
        After being forced to spend a few months together, with Naamah holding Kevin’s leash, they are finally free to go their separate ways and return to life as it was before their fateful tumble. After all, that’s what they both wanted. Or is it?
        <br/>
        <br/>
        Trigger warnings:
        <br />
        <br />✣ Mentions of abusive foster parents
        <br />✣ Mentions of drug use <br />✣ Mentions of prostitution <br />✣ Mentions of suicidal ideation <br />✣ Mentions of self-harm <br />✣ Homelessness <br />✣ Adult language<br />✣ Graphic Violence<br />✣ Explicit sexual scenes which include: dubious consent, power play, bondage, spanking, pet play, degradation/humiliation, inhuman body parts, and pegging.
      </>
    ),
    link: "https://mybook.to/tros",
  },
  {
    id: 4,
    title: "a hellish christmas short story duology - the Rivers of Hell Christmas Novelette",
    cover: "/novelettecover.png",
    blurb: (
      <>
        <br />
        In 1967, the demon Nephithar and the angel Syriniana bring holiday magic to a small town, blending heartwarming moments and sizzling passion as they help a struggling family business during the season of giving.
        Today, Lana transforms the archdemon Ashtaroth's foreboding castle in Hell into a winter wonderland – with a little help from the mischievous baby imp Puck. Dinners in Hell are a fiery affair, and this Christmas Eve is no different.
        Heartfelt, steamy, and perfect for a quick escape, this The Rivers of Hell novelette delivers the ideal dose of Christmas joy.
        <br/>
        <br/>
        Trigger warnings:
        <br />
        <br />✣ Liberal interpretation of religious and occult texts
        <br />✣ Adult language <br />✣ Explicit sexual scenes which include bondage and power play
      </>
    ),
    link: "https://mybook.to/hellishchristmas",
  },
  {
    id: 5,
    title: "a hellish valentine’s day - the Rivers of Hell Valentine's Novelette",
    cover: "/vcover.png",
    blurb: (
      <>
        <br />
        Kevin, Hell’s most chaotic charmer, just wants to give his succubus lover, Naamah, the perfect Valentine’s Day gift. His solution? A daring mission to steal The Heart of Eros, a priceless relic said to embody eternal passion. Romantic? Sure. Reckless? Absolutely. But before Kevin can sneak off on his grand gesture, Naamah catches him in the act – and there’s no way she’s letting him have all the fun alone.
        <br/>
        Filled with spice, laughter, and a dash of chaos, A Hellish Valentine’s Day is a cozy, steamy romp that proves love really is Hell – especially when Kevin’s involved.
        <br/>
        <br/>
        Trigger warnings:
        <br />
        <br />✣ Adult language
        <br />✣ BDSM power dynamics (with a role reversal) <br />✣ Explicit sexual scenes
        <br />✣ Pegging <br />✣ Inhuman body parts
        <br />✣ Breeding kink
        <br />✣ Mentions of drug use <br />✣ Mentions of homelessness
      </>
    ),
    link: "https://mybook.to/hellishvd",
  },
  {
    id: 6,
    title: "the river of hatred - the Rivers of Hell - book 2",
    cover: "/troh.jpg",
    blurb: (
      <>
        <i>A fallen angel with a grudge. A warrior of Heaven with too much to lose. And a Nephilim caught between them.
        </i>
        <br/>
        <br />
        Once, Sariel would have done anything for Ithuriel. Now? He just wants to watch him fall. When the angels embark on a mission to close the rift between Hell and the human world, Sariel sees his chance for vengeance. Ithuriel let him suffer alone for centuries – now he’ll make sure the righteous angel knows exactly what it means to fall. And if he has to use their captivating Nephilim companion, Jessica, to do it? Even better.
        As they descend into the twisted depths of the Malebolge, facing the damned and the demons who rule them, Sariel’s game of seduction takes a dangerous turn. Temptation was supposed to be his weapon. He never expected it to feel like salvation. And when the mission takes them beyond the Underworld, into a world where Heaven and Hell both have stakes, the three will have to decide what’s more dangerous – the ever-growing demonic conspiracy or their forbidden desires.
        <br/>
        <br/>
        Trigger warnings:
        <br />
        <br />✣ Liberal interpretation of religious and occult texts
        <br />✣ Morally gray MMC
        <br />✣ Graphic violence
        <br />✣ Adult language
        <br />✣ Falling from grace
        <br />✣ Mention of misscarriages
        <br />✣ Graphic sexual scenes which include: exhibitionism & voyeurism, MM sexual content, MF sexual content, MMF sexual content, DP (double penetration), anal sex (male and female), and a breeding kink
      </>
    ),
    link: "https://mybook.to/troha ",
  },
  {
    id: 6,
    title: "dusk & desire",
    cover: "/d&d.jpg",
    blurb: (
      <>
        College was already hell and Linda thought her day couldn’t get any worse. Then the world ended. The sky split open and alien ships descended in a fiery rain from above.
        <br/>
        Amid the apocalypse, Linda is forced into a game of survival with the mysterious Noa – a sharp-tongued rebel with a reckless grin. Noa has the skills Linda lacks, but she also has a way of making her question everything she thought she knew about herself.
        <br/>
        As the danger escalates, so does the tension between them. The two girls realize that surviving isn’t enough. You need something to fight for.
        <br/>
        <br/>
        Trigger warnings:
        <br />
        <br />✣ Graphic violence
        <br />✣ Adult language
        <br />✣ Alien Invasion/Fall of Civilization  
        <br />✣ Graphic sexual scenes 
      </>
    ),
    link: "https://mybook.to/duskdesire ",
  },
];

const Books = () => {
  const cardDirection = useBreakpointValue({ base: "column", sm: "row" });

  return (
    <Box p={4} display="flex" alignItems="center" flexDir="column" mb={20}>
      <Heading as="h1" size="xl" mb={4}>
        Books
      </Heading>
      <Box maxW="100%">
        {booksData.map((book) => (
          <Flex
            key={book.id}
            mb={4}
            p={2}
            maxW="container.lg"
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            bg="white"
            color="black"
            alignItems="center"
            flexDirection={cardDirection}
          >
            <Image
              src={book.cover}
              alt={book.title}
              maxW="200px"
              objectFit="cover"
            />
            <Box p="4">
              <Heading as="h2" size="md" mb={2}>
                {book.title}
              </Heading>
              <Text fontSize="lg" fontWeight="bold" mb={4}>
                {book.blurb}
              </Text>
              {book.link && (
                 <Link href={book.link} isExternal color="black" target="_blank">
                <Text fontSize="lg" fontWeight="bold" color="#d791a6">
                  Shop Now
                </Text>
              </Link>
              )}
            
            </Box>
          </Flex>
        ))}
      </Box>
    </Box>
  );
};

export default Books;
