import React from "react";
import {
  Box,
  Grid,
  GridItem,
  Text,
  Heading,
  Button,
  Container,
} from "@chakra-ui/react";
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import SlickCarousel from 'react-slick';

const servicesData = [
  {
    id: 1,
    title: "Proofreading",
    price: "$0.003 per word",
    description: (
      <>
        <Text>The final polish for a flawless manuscript:</Text>
        <Text>✔ Grammar, punctuation, and spelling corrections</Text>
        <Text>✔ Consistent formatting for a professional look</Text>
      </>
    ),
    serviceEmailSubject: "Inquiry about Proofreading Service",
  },
  {
    id: 2,
    title: "Line Editing",
    price: "$0.006 per word",
    description: (
      <>
        <Text>Enhance your prose and elevate your story:</Text>
        <Text>✔ Sentence restructuring for clarity and flow</Text>
        <Text>✔ Improved word choice and tone consistency</Text>
        <Text>✔ Detailed feedback to refine your manuscript</Text>
      </>
    ),
    serviceEmailSubject: "Inquiry about Line Editing Service",
  },
  {
    id: 3,
    title: "Copy Editing",
    price: "$0.005 per word",
    description: (
      <>
        <Text>A thorough technical and stylistic edit to make your manuscript shine:</Text>
        <Text>✔ Grammar, punctuation, and spelling corrections</Text>
        <Text>✔ Refinement of sentence structure and word choice</Text>
        <Text>✔ Ensures consistency in style, tone, and formatting</Text>
        <Text>✔ Fact-checking for accuracy (names, dates, timelines)</Text>
        <Text>✔ Checks for minor plot or character inconsistencies</Text>
      </>
    ),
    serviceEmailSubject: "Inquiry about Copy Editing Service",
  },
  {
    id: 4,
    title: "Line/copy Editing Combo",
    price: "$0.007 per word",
    description: (
      <>
        <Text>The best of both worlds—polish your manuscript with a comprehensive edit:</Text>
        <Text>✔ Grammar, punctuation, and spelling corrections</Text>
        <Text>✔ Refinement of sentence structure and word choice</Text>
        <Text>✔ Ensuring consistency in style, tone, and formatting</Text>
        <Text>✔ Fact-checking for accuracy (names, dates, timelines)</Text>
        <Text>✔ Checking for minor plot or character inconsistencies</Text>
        <Text>✔ Restructuring sentences for clarity and flow</Text>
        <Text>✔ Providing detailed feedback to refine your manuscript</Text>
      </>
    ),
    serviceEmailSubject: "Inquiry about Line/copy Editing Combo ",
  },
  {
    id: 5,
    title: "Complete Editing package",
    price: "$0.008 per word",
    description: (
      <>
        <Text>A full-service edit to perfect your manuscript:</Text>
        <Text>✔ Copy Editing – Grammar, punctuation, and spelling corrections</Text>
        <Text>✔ Line Editing – Refining sentence structure, clarity, and tone consistency</Text>
        <Text>✔ Fact-checking – Ensuring accuracy in names, dates, and timelines</Text>
        <Text>✔ Consistency Check – Style, formatting, and minor plot/character details</Text>
        <Text>✔ Detailed Feedback – Enhancing prose and strengthening your story</Text>
        <Text>✔ Proofreading – A final pass to catch lingering typos and errors</Text>
        <Text>This package ensures your manuscript is polished and publication-ready.</Text>
      </>
    ),
    serviceEmailSubject: "Inquiry about Complete Editing package",
  },
  {
    id: 5,
    title: "Blurb Job",
    price: "from $15-$50",
    description: (
      <>
        <Text>Basic blurb polish ($15-$20)</Text>
        <Text>✔ Light grammar, clarity, and flow fixes</Text>
        <br/>
        <Text>Blurb rewrite ($25-$30)</Text>
        <Text>✔ Tightening the hook, improving tone, enhancing emotional impact</Text>
        <br/>
        <Text>Blurb + tagline package ($35-$50)</Text>
        <Text>✔ A full rework with a snappy one-liner to boost marketing appeal</Text>
      </>
    ),
    serviceEmailSubject: "Inquiry about Blurb job",
  },
];

const testimonials = [
  {
    name: 'Marianne Grey, author of Of Shadow and Argent',
    feedback: "The grammar fiend every author needs in their corner.",
  },
  {
    name: 'A.Marie, author of Intertwined Hearts and Forgotten Secrets',
    feedback: `Li completely turned my story around while keeping true to the material I gave her. She also helped educate me on mistakes I was making. She's your girl for all grammar needs!`,
  },
  {
    name: 'Meg Stratton, author of Red is for Roxy',
    feedback: `Liana was amazing to work with!! I will be using her going forward for my books!! Her support and clear explanations of where I need to focus as I write were appreciated and frankly spot on! Check her out!!`,
  },
];

const Services = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Box p={4} mb={20}>
      <Heading as="h1" size="xl" mb={8} textAlign="center">
        Services
      </Heading>

      {/* Single Book my services Button */}
      <Button
        as="a"
        href="mailto:liana.valerian@gmail.com?subject=Inquiry about Services&body=Hello, I am interested in your services. Please provide me with more details."
        bgColor="#d791a6"
        size="lg"
        mb={8}
        width="fit-content"
        align-items="center"
        justify-content="center"
        display="flex"
        mx="auto"
        _hover={{
          bg: "#101925",
          color: "#d791a6",
        }}
      >
        Book my services!
      </Button>

      {/* Service grid */}
      <Container maxW="container.md">
        <Grid
          templateColumns={{
            base: "1fr", // Single column for small screens
            md: "1fr 1fr", // Two columns for medium and larger screens
          }}
          gap={6}
        >
          {servicesData.map((service) => (
            <GridItem
              key={service.id}
              p={4}
              borderWidth="1px"
              borderRadius="lg"
              bg="white"
              color="black"
              shadow="md"
              _hover={{ shadow: "lg", transform: "scale(1.02)" }}
              transition="all 0.2s"
            >
              <Heading as="h2" size="md" mb={2}>
                {service.title}
              </Heading>
              <Text fontSize="lg" fontWeight="bold" mb={4} color="#d791a6">
                {service.price}
              </Text>
              <Box fontSize="md" color="gray.700">
                {service.description}
              </Box>
            </GridItem>
          ))}
        </Grid>
      </Container>

      {/* Testimonials Carousel */}
      <Box mt={12} width="60%" mx="auto">
        <Heading as="h2" size="lg" mb={4} textAlign="center">
          Testimonials
        </Heading>
        <SlickCarousel {...settings}>
          {testimonials.map((testimonial, index) => (
            <Box key={index} textAlign="center">
              <Text fontSize="xl" fontStyle="italic" mb={4}>
                "{testimonial.feedback}"
              </Text>
              <Text fontSize="lg" fontWeight="bold">
                - {testimonial.name}
              </Text>
            </Box>
          ))}
        </SlickCarousel>
      </Box>
    </Box>
  );
};

export default Services;
