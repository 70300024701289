import React, { useState } from "react";
import { Box, Heading, SimpleGrid, Image, Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";

const ArtPage = () => {
  return (
    <Box p={4} display="flex" flexDir="column" alignItems="center" justifyContent="center">
      <Heading as="h1" size="xl" mb={6}>
        Art Gallery
      </Heading>
      <Tabs variant="soft-rounded" colorScheme="green">
        <TabList>
          <Tab>OBAC Art</Tab>
          <Tab>TROH Art</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <ArtGrid art={obacArt} />
          </TabPanel>
          <TabPanel>
            <ArtGrid art={trohArt} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

const ArtGrid = ({ art }) => {
  return (
    <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10} mb={10} display="flex" flexWrap="wrap" justifyContent="center" alignItems="center">
      {art.map((art, index) => (
        <ArtItem key={index} art={art} />
      ))}
    </SimpleGrid>
  );
};

const ArtItem = ({ art }) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Image src={art.image} alt={art.name} borderRadius="md" width="300px" height="400px" objectFit="cover" />
    </Box>
  );
};

const obacArt = [
  { name: "ObacArt 1", image: "/obac1.png" },
  { name: "ObacArt 2", image: "/obac2.png" },
  { name: "ObacArt 3", image: "/obac3.PNG" },
  { name: "ObacArt 4", image: "/obac4.jpeg" },
  { name: "ObacArt 5", image: "/obac5.png" },
  { name: "ObacArt 6", image: "/obac6.png" },
];

const trohArt = [
  { name: "Art 1", image: "/Ash-2.jpg" },
  { name: "Art 2", image: "/ash&lana-book.jpg" },
  { name: "Art 3", image: "/image0.jpeg" },
  { name: "Art 4", image: "/image1.jpeg" },
  { name: "Art 5", image: "/image2.jpeg" },
  { name: "Art 6", image: "/image3.jpeg" },
  { name: "Art 7", image: "/image4.jpeg" },
  { name: "Art 8", image: "/image5.jpeg" },
  { name: "Art 9", image: "/image6.jpeg" },
  { name: "Art 10", image: "/Puck.jpg" },
  { name: "Art 11", image: "/IMG_7850.jpg" },
  { name: "Art 12", image: "/a&l.jpg" },
  { name: "Art 13", image: "/IMG_7904.jpg" },
  { name: "Art 14", image: "/image01.jpeg" },
  { name: "Art 15", image: "/IMG_8441.jpeg" },
  { name: "Art 16", image: "/IMG_8450.jpeg" },
  { name: "Art 17", image: "/10.jpg" },
  { name: "Art 18", image: "/11.jpg" },
  { name: "Art 19", image: "/12.png" },
  { name: "Art 20", image: "/222.jpeg" },
  { name: "Art 21", image: "/111.jpg" },
  { name: "Art 22", image: "/555.png" },
  { name: "Art 23", image: "/666.png" },
  { name: "Art 24", image: "/777.jpeg" },
  { name: "Art 25", image: "/888.png" },
  { name: "Art 26", image: "/999.png" },
  { name: "Art 27", image: "/IMG_1055.jpeg" },
  { name: "Art 28", image: "/27.png" },
  { name: "Art 29", image: "/28.png" },
  { name: "Art 30", image: "/29.png" },
  { name: "Art 31", image: "/30.png" },
  { name: "Art 32", image: "/31.png" },
  { name: "Art 33", image: "/32.png" },
  { name: "Art 34", image: "/33.png" },
  { name: "Art 35", image: "/34.JPG" },
];

export default ArtPage;
