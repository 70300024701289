// src/components/Readings.js
import React from "react";
import { Box, Heading, SimpleGrid } from "@chakra-ui/react";

const Readings = () => {
  return (
    <Box
      p={4}
      display="flex"
      flexDir="column"
      alignItems="center"
      justifyContent="center"
    >
      <Heading as="h1" size="xl" mb={10}>
        readings
      </Heading>
      <VideoGrid videos={videos} />
    </Box>
  );
};

const VideoGrid = ({ videos }) => {
  return (
    <SimpleGrid
      columns={{ base: 1, md: 2, lg: 3 }} // Responsive grid layout
      spacing={8}
      mb={10}
      justifyContent="center"
      alignItems="center"
    >
      {videos.map((video, index) => (
        <Video key={index} video={video} />
      ))}
    </SimpleGrid>
  );
};

const Video = ({ video }) => {
  return (
    <Box
      as="video"
      src={video.src}
      controls
      loop
      autoPlay
      muted
      width="100%"
      maxWidth="400px"
      borderRadius="md"
      boxShadow="xl"
      p={2}
    />
  );
};

// Dummy data for videos
const videos = [
  {
    src: "/vid1.mov", // Path is directly from the public folder
  },
  {
    src: "/vid2.mov",
  },
  {
    src: "/vid3.mov",
  },
  {
    src: "/vid4.mov",
  },
  {
    src: "/vid5.mp4",
  },
  {
    src: "/vid6.mp4",
  },
  {
    src: "/reading.mp4",
  },
  {
    src: "/reading2.mov",
  },
  {
    src: "/reading3.mp4",
  },

];

export default Readings;
